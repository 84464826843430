import { useEffect, useState } from 'react';
// import { useHistory } from "react-router-dom";


// PACKAGES
import styled from 'styled-components';

// COMPONENTS
import OralLessons from './OralLessons';

// PAGE


// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getAllOralSubjects } from '../../../Store/Actions/oraleActions';

//import { getAllSubjects } from '../../../Store/Actions/subjectsActions';

// STYLE
import {
    WrapperRightSide,
    // Title,
    TestWrapper,
    // ActionsWrapper,
    BtnWrapper,
    Btn,
} from '../PagesShareStyle/PagesShareStyle';

const MateriaWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const Header = styled.div`
    width: 100%;
    height: 60px;
    border-radius: var(--radius) var(--radius) 0 0;
    background: var(--acc-color);
    color: var(--text5);
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: var(--fontL);
    font-weight: bold;
    z-index: 1;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
`;

const Lesson = styled.div`
    position: relative;
    width: 100%;
    height: 60px;
    background: var(--background3);
    padding: 10px 40px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--fontL);
    font-weight: bold;
    cursor: pointer;
        &:hover{
            background: var(--text4);
        }
`;

const Left = styled.div`
    
    
`;

const Right = styled.div`
    >span {
        font-size: 14px;
    }
    >i{
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%) ${props => props.open === 'true' && 'rotate(-90deg)'};
        color: var(--text3);

    }
`;

const Sts = styled.div`
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--text5);
    padding: 5px 15px;
    border-radius: var(--radius);
    background-color: ${props =>
        (props.color === 'In corso' && 'var(--warning)') ||
        (props.color === 'Completo' && 'var(--success)')
    };
    >div:nth-last-child(1){
        border-radius:  0 0 var(--radius) var(--radius);
    }
`;


const Materia = ({ idCourse, idCheckUser, studDetails, course }) => {
    const dispatch = useDispatch();
    const profile = useSelector(({ auth }) => auth.user);
    // const materie = useSelector(({ subjects }) => subjects.allSubjects);
    const materie = useSelector(({ orale }) => orale.allOralSubs);

    const [openLessons, setOpenLessons] = useState(false);
    const [mats, setMats] = useState();

    useEffect(() => {
        const id = idCheckUser !== undefined ? idCheckUser : profile.idUser;
        dispatch(getAllOralSubjects(idCourse, id));
    }, [dispatch]);

    useEffect(() => {
        let ret = []
        //console.log("")
        if (materie !== undefined) {
            materie.forEach(el => {
                //if (el.visibility === 'Tutti' || el.visibility === 'Apprendimento') {
                ret.push(el)
                // }
            })
            //ret.sort((a, b) => a.order - b.order)
            // console.log("ret", ret);
            //setMats(ret);

            // FILTER AND EXCLUDE
            if (ret !== undefined) {
                console.log({ ret })
                let newQue = [];
                for (let i = 0; i < ret.length; i++) {
                    const prop = ret[i].OralSubjects_idOralSubjects_OralSubject.OralSubjects_has_OralLessons;
                    let ifNull = []
                    let notNulll = []
                    ifNull = prop.filter(
                        el => (el.OralLessons_idOralLesson_OralLesson.excluded === null))
                    notNulll = prop.filter(
                        el =>
                            (el.OralLessons_idOralLesson_OralLesson.excluded !== null && !JSON.parse(el.OralLessons_idOralLesson_OralLesson.excluded).find(id => Number(id) === course.idCourse)));
                    //console.log("aioaoaoaaoao", notNulll)
                    ret[i].OralSubjects_idOralSubjects_OralSubject.OralSubjects_has_OralLessons = ifNull && notNulll && ifNull?.concat(notNulll)
                    console.log("#################", ret[i].OralSubjects_idOralSubjects_OralSubject.OralSubjects_has_OralLessons)
                    ret[i].OralSubjects_idOralSubjects_OralSubject.OralSubjects_has_OralLessons
                        .sort((a, b) => {
                            const numeroA = parseInt(a.OralLessons_idOralLesson_OralLesson.name.match(/\d+/)?.[0] || 0, 10);
                            const numeroB = parseInt(b.OralLessons_idOralLesson_OralLesson.name.match(/\d+/)?.[0] || 0, 10);
                            return numeroA - numeroB;
                        })
                    // ret[i].OralSubjects_idOralSubjects_OralSubject.OralSubjects_has_OralLessons
                    //     .sort((a, b) => a.OralLessons_idOralLesson - b.OralLessons_idOralLesson)
                    // ret[i].OralSubjects_idOralSubjects_OralSubject.OralSubjects_has_OralLessons
                    //     .sort((a, b) => a.OralLessons_idOralLesson_OralLesson.name < b.OralLessons_idOralLesson_OralLesson.name)
                    // ret[i].OralSubjects_idOralSubjects_OralSubject.OralSubjects_has_OralLessons.sort((a, b) => a.OralLessons_idOralLesson_OralLesson.name > b.OralLessons_idOralLesson_OralLesson.name)

                }

                setMats(ret);
            }
            // if (ret !== undefined) {
            //     for (let i = 0; i < ret.length; i++) {
            //         const prop = ret[i].OralSubjects_idOralSubjects_OralSubject.OralSubjects_has_OralLessons;

            //         let flag = false;
            //         for (let j = 0; j < prop.length; j++) {
            //             let newQue = [];
            //             if (prop[j].OralLessons_idOralLesson_OralLesson.excluded === null) {
            //                 setMats(ret);
            //                 //newQue.push(ret[i]);
            //             } else {
            //                 //console.log("STOKAZZO", prop[j])
            //                 flag = true
            //                 const findIfExists = JSON.parse(prop[j].OralLessons_idOralLesson_OralLesson.excluded).find(id => id === course.idCourse);
            //                 console.log("TROVATOOOO", findIfExists)
            //                 if (!findIfExists) {
            //                     console.log("RET[III]", ret[i])
            //                     newQue.push(ret[i]);
            //                 }
            //                 setMats(newQue);
            //             }
            //         }
            //         if (flag) { setMats(ret) }
            //     }

            // }
            // if (ret !== undefined) {
            //     //let newOne = [];
            //     for (let i = 0; i < ret.length; i++) {
            //         const prop = ret[i].OralSubjects_idOralSubjects_OralSubject.OralSubjects_has_OralLessons;
            //         let flag = false;
            //         for (let j = 0; j < prop.length; j++) {
            //             if (prop[j].OralLessons_idOralLesson_OralLesson.excluded === null) {
            //                 setMats(ret);
            //                 //newOne.push(ret[i]);
            //             } else {
            //                 flag = true
            //                 let newOne = [];
            //                 const findIfExists = JSON.parse(prop[j].OralLessons_idOralLesson_OralLesson.excluded).find(id => id === course.idCourse);
            //                 console.log("QUESTOOOO", prop[j])
            //                 if (!findIfExists) {
            //                     newOne.push(ret[i]);
            //                 }
            //                 setMats(newOne);
            //             }
            //         }
            //         if (flag) { setMats(ret) }
            //     }
            //     //setMats(newOne);
            // }
        }
    }, [materie, course.idCourse]);

    console.log('mats', mats)
    // console.log("DETAILS", idCourse);
    // console.log('MATERIA', mats);
    // console.log("MATERIE", materie);    
    // console.log("PROFILE", profile);


    return (
        (mats !== undefined && mats.length > 0) &&
        <MateriaWrapper>
            <Header>{mats !== undefined && mats.length} Materie</Header>
            {mats !== undefined &&
                mats.map((mat, i) =>
                    <div key={i}>
                        <OralLessons
                            mat={mat}
                            idCheckUser={idCheckUser}
                            studDetails={studDetails}
                            course={course}
                        />
                    </div>
                )}
        </MateriaWrapper>
    );
}

export default Materia;
