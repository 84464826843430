import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// PACKAGES
import styled from 'styled-components';
//import axios from 'axios';

import Vimeo from '@u-wave/react-vimeo';
// import YouTube, { YouTubeProps } from 'react-youtube';

// onst { getVideoDurationInSeconds } = require('get-video-duration')

// COMPONENTS
import Quest from './Quest/Quest';

// PAGE

// REDUX
import { useSelector, useDispatch } from 'react-redux';
//import { getAllQuestionnaires, videoIsDone } from '../../../Store/Actions/subjectsActions';
import { getAllOralQuestionnaires, videoOraleIsDone } from '../../../../Store/Actions/oraleActions'

// STYLE
import {
    WrapperRightSide,
    // Title,
    TestWrapper,
    // ActionsWrapper,
    BtnWrapper,
    Btn,
} from '../../PagesShareStyle/PagesShareStyle';
//import Profile from '../Profile/Profile';

const TitleWrapper = styled.div`
    position: relative;
    /* display: flex; */
`;

const Title = styled.h3`
  /* width:100%; */
  color:var(--text2);
  display: flex;
  align-items: center;
  font-weight: bold;
  /* margin-top: 10px; */
`;

const VideoWrapper = styled.div`
    position: relative;
    /* width: 100%;
    height: calc(100vh - 300px); */
    margin-top: 20px;
    background: var(--background4);
    display: flex;
    align-items: center;
    justify-content: center;
    /* .video{
        width: 100%;
    } */
    /* >video{
        width: 100%;
    } */
`;

const InfoWrapper = styled.div`
    width: 100%;
    margin-top: 30px;
`;

const Info = styled.div`
    margin-top: 20px;
    width: 100%;
    font-size: var(--fontL);
    color: var(--text2);
`;

const Header = styled.div`
    position: relative;
    margin-top: 20px;
    width: 100%;
    height: 60px;
    border-radius: var(--radius) var(--radius) 0 0;
    background: var(--acc-color);
    color: var(--text5);
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: var(--fontL);
    font-weight: bold;
    z-index: 1;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
`;

const Sts = styled.div`
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--text5);
    padding: 2px 10px;
    /* min-width: 70px; */
    height: 24px;
    /* margin-right: 10px; */
    border-radius: var(--radius);
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: ${props =>
        (props.color === 'In corso' && 'var(--warning)') ||
        (props.color === 'Completo' && 'var(--success)') ||
        (props.color === 'In attesa' && 'var(--background1)')
    }; */
    background-color: ${props =>
        (props.color === 'Videolezione completata' && 'var(--success)') ||
        (props.color === 'Videolezione non completata' && 'var(--text2)')
    };
`;


const OraleLessonPage = ({ details }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const profile = useSelector(({ auth }) => auth.user);
    const quest = useSelector(({ orale }) => orale.allOralQuest);
    const [quests, setQuests] = useState();
    const props = details.less.OralLessons_idOralLesson_OralLesson;
    const [videoTime, setVideoTime] = useState();

    useEffect(() => {
        // console.log("RULEAZAAAAA")
        const idUser = details.idCheckUser !== undefined ? details.idCheckUser : profile.idUser;
        // console.log("iduser", idUser);
        dispatch(getAllOralQuestionnaires(details.less.OralLessons_idOralLesson, idUser));
    }, [dispatch]);

    const endVideo = () => {
        dispatch(
            videoOraleIsDone(
                details.less.OralLessons_idOralLesson,
                details.less.OralSubjects_idOralSubject,
                details.course.idCourse,
                videoTime
            ));
        // console.log('end');
    };

    const custom = {
        width: '100%',
        maxWidth: '2000px'
    };

    const capitalizeFirstLetter = (str) => {
        return str[0].toUpperCase() + str.slice(1).toLowerCase();
    };

    useEffect(() => {
        if (quest !== undefined && quest.length > 0) {
            let newOne = [];
            const que = quest;
            for (let i = 0; i < que.length; i++) {
                if (que[i].excluded === null) {
                    newOne.push(que[i]);
                } else {
                    const findIfExists = JSON.parse(que[i].excluded).find(id => id === details.course.idCourse);
                    if (!findIfExists) {
                        newOne.push(que[i]);
                    }
                }
            }
            setQuests(newOne);
        }
    }, [quest]);

    // console.log("QUEST LESSON", quest);
    // console.log('DETAILS LESSON', details.less);
    // console.log("PROPS", props);
    // console.log("DETAILS", details);

    return (
        <>
            <TitleWrapper>
                {/* <TitleWrapper >
                    <GoBack onClick={() => history.goBack()}>
                        <i className="fas fa-arrow-left"></i>
                        <span>Torna alla Materia</span>
                    </GoBack>
                </TitleWrapper> */}

                <Title>{props.name}</Title>

                {props.OralLessonCompletes.length > 0 ?
                    <Sts color={'Videolezione completata'}>{'Videolezione completata'}</Sts>
                    :
                    props.ondemand !== null ?
                        <Sts color={'Videolezione non completata'}>{'Videolezione non completata'}</Sts>
                        :
                        null
                }


                {details.studDetails !== undefined &&
                    <>
                        {capitalizeFirstLetter(details.studDetails.user_role)}: {details.studDetails.user_name} {details.studDetails.user_surname}
                    </>
                }
            </TitleWrapper>

            <VideoWrapper >
                <Vimeo
                    //video="https://player.vimeo.com/video/748050997"
                    video={props.ondemand}
                    style={custom}
                    onEnd={endVideo}
                    autoplay={false}
                    responsive={true}
                    sbackground={true}
                    onPause={(e) => console.log('PROPS', e)}
                // onProgress={(e)=>setVideoTime(e.duration)}
                />
            </VideoWrapper>

            <InfoWrapper>
                <Title>{props.title}</Title>
                <Info>{props.body}</Info>
            </InfoWrapper>

            {/* <TitleWrapper style={{ marginTop: '20px' }}>
                <GoBack onClick={() => history.goBack()}>
                    <i className="fas fa-arrow-left"></i>
                    Torna alla Materia
                </GoBack>
            </TitleWrapper> */}

            {
                quests !== undefined &&
                quests.length > 0 &&
                <>
                    <Header>Verifiche
                        <Sts
                            style={{ right: '10px' }}
                            color={details.less.sts}
                        >
                            {details.less.sts}
                        </Sts>
                    </Header>


                    {quests !== undefined &&
                        quests.map((que, i) =>
                            <div key={i}>
                                <Quest
                                    que={que}
                                />
                            </div>
                        )}
                </>
            }
        </>
    );
}

export default OraleLessonPage;
